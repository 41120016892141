var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "form-title" },
        [
          _c("span", [_vm._v("盘库信息")]),
          _c("el-button", { on: { click: _vm.handleExport } }, [
            _vm._v("导出"),
          ]),
        ],
        1
      ),
      _c(
        "vxe-table",
        { attrs: { data: _vm.storeList, "show-overflow": "" } },
        [
          _c("vxe-table-column", { attrs: { type: "seq", width: "60" } }),
          _c("vxe-table-column", {
            attrs: { type: "expand", title: "产品详情" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "vxe-table",
                      {
                        attrs: {
                          data: row.visitStepStockList,
                          "show-overflow": "",
                        },
                      },
                      [
                        _c("vxe-table-column", {
                          attrs: {
                            title: "产品层级编码",
                            field: "productLevelCode",
                          },
                        }),
                        _c("vxe-table-column", {
                          attrs: {
                            title: "产品层级名称",
                            field: "productLevelName",
                          },
                        }),
                        _c("vxe-table-column", {
                          attrs: { title: "产品编码", field: "productCode" },
                        }),
                        _c("vxe-table-column", {
                          attrs: { title: "产品名称", field: "productName" },
                        }),
                        _c("vxe-table-column", {
                          attrs: { title: "数量", field: "quantity" },
                        }),
                        _c("vxe-table-column", {
                          attrs: { title: "单位", field: "saleUnitName" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: { title: "盘库时间", field: "stockTime" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "商品数量", field: "stockNum" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "盘库人员", field: "realName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "盘库定位", field: "stockAddress" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "所属组织", field: "orgName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "职位名称", field: "posName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "系列数", field: "seriesNum" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "商品种数", field: "typeNum" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-pageination" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page.pageNum,
              "page-sizes": [5, 10, 15, 20, 50, 100, 150, 200],
              "page-size": _vm.page.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.page.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("ExportModal", { attrs: { propsObj: _vm.propsObjInData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }