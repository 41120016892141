<template>
  <el-form label-width="130px">
    <el-row>
      <el-col :span="8">
        <el-form-item label="客户编码:">
          <TextEllpsis :text="inventoryInfo.clientCode"></TextEllpsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="客户名称:">
          <TextEllpsis :text="inventoryInfo.clientName"></TextEllpsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="客户类型:">
          <TextEllpsis :text="inventoryInfo.clientTypeName"></TextEllpsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="客户地址:">
          <TextEllpsis :text="inventoryInfo.stockAddress"></TextEllpsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="库存数量:">
          <TextEllpsis :text="inventoryInfo.stockNum"></TextEllpsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="库存差:">
          <TextEllpsis :text="inventoryInfo.stockDiffer"></TextEllpsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="最近一次盘库时间:">
          <TextEllpsis :text="inventoryInfo.stockTime"></TextEllpsis>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import TextEllpsis from '../../../../../../components/text_ellipsis';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  components: { TextEllpsis },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && isObject(curVal)) {
          this.inventoryInfo = { ...this.inventoryInfo, ...curVal };
        }
      },
    },
  },
  data() {
    return {
      inventoryInfo: {
        clientCode: '', // 客户编码
        clientName: '', // 客户名称
        clientType: '', // 客户类型
        stockAddress: '', // 库存地址
        stockNum: '', // 库存数量
        stockDiffer: '', // 库存差
        lastStockTime: '', // 上次盘库时间
      },
    };
  },
};
</script>
<style lang="less" scoped>
  /deep/ .text {
    font-size: 14px;
  }
</style>
