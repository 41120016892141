var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "130px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户编码:" } },
                [
                  _c("TextEllpsis", {
                    attrs: { text: _vm.inventoryInfo.clientCode },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称:" } },
                [
                  _c("TextEllpsis", {
                    attrs: { text: _vm.inventoryInfo.clientName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户类型:" } },
                [
                  _c("TextEllpsis", {
                    attrs: { text: _vm.inventoryInfo.clientTypeName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户地址:" } },
                [
                  _c("TextEllpsis", {
                    attrs: { text: _vm.inventoryInfo.stockAddress },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "库存数量:" } },
                [
                  _c("TextEllpsis", {
                    attrs: { text: _vm.inventoryInfo.stockNum },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "库存差:" } },
                [
                  _c("TextEllpsis", {
                    attrs: { text: _vm.inventoryInfo.stockDiffer },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "最近一次盘库时间:" } },
                [
                  _c("TextEllpsis", {
                    attrs: { text: _vm.inventoryInfo.stockTime },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }