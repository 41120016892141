<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';

import DetailInfo from './components/detail_info.vue';
import DetailInventory from './components/detail_inventory.vue';

formCreate.component('sfavisitManageInventoryDetailInfo', DetailInfo);
formCreate.component('sfavisitManageInventoryDetailInventory', DetailInventory);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
      clientTypeName: '',
    };
  },
  methods: {
    // 获取客户类型名称
    async getClientTypeName(dictCode) {
      const requestUrl = '/mdm/mdmdictdata/list';
      const { result } = await request.post(requestUrl, { dictCode, dictTypeCode: 'sfacusType' });
      this.clientTypeName = result.find((item) => item.dictCode === dictCode).dictValue;
    },
    getDetail() {
      this.setValue({
        field_info: this.formConfig.row,
        field_inventory: this.formConfig.row,
      });
    },
  },
  async created() {
    await this.getFormRule('sfaCenter_visitManage_inventoryDetail_detailForm');
    // 查询客户类型Name
    if (!this.formConfig.row.clientTypeName) {
      await this.getClientTypeName(this.formConfig.row.clientType);
      this.formConfig.row.clientTypeName = this.clientTypeName;
    }
    if (this.formConfig.row) this.getDetail();
  },
};
</script>
