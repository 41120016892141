<template>
  <div>
    <div class="form-title">
      <span>盘库信息</span>
      <el-button @click="handleExport">导出</el-button>
    </div>
    <vxe-table :data="storeList" show-overflow >
      <vxe-table-column type="seq" width="60"></vxe-table-column>
      <vxe-table-column type="expand" title="产品详情">
        <template v-slot:content="{row}">
          <vxe-table :data="row.visitStepStockList" show-overflow>
            <vxe-table-column title="产品层级编码" field="productLevelCode"></vxe-table-column>
            <vxe-table-column title="产品层级名称" field="productLevelName"></vxe-table-column>
            <!-- <vxe-table-column title="上级产品层级" field="topProductLevelName"></vxe-table-column> -->
            <vxe-table-column title="产品编码" field="productCode"></vxe-table-column>
            <vxe-table-column title="产品名称" field="productName"></vxe-table-column>
            <vxe-table-column title="数量" field="quantity"></vxe-table-column>
            <vxe-table-column title="单位" field="saleUnitName"></vxe-table-column>
          </vxe-table>
        </template>
      </vxe-table-column>
      <vxe-table-column title="盘库时间" field="stockTime"></vxe-table-column>
      <vxe-table-column title="商品数量" field="stockNum"></vxe-table-column>
      <vxe-table-column title="盘库人员" field="realName"></vxe-table-column>
      <vxe-table-column title="盘库定位" field="stockAddress"></vxe-table-column>
      <vxe-table-column title="所属组织" field="orgName"></vxe-table-column>
      <vxe-table-column title="职位名称" field="posName"></vxe-table-column>
      <vxe-table-column title="系列数" field="seriesNum"></vxe-table-column>
      <vxe-table-column title="商品种数" field="typeNum"></vxe-table-column>
    </vxe-table>
    <div class="page-pageination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageNum"
        :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
    <!-- 导出弹窗 -->
    <ExportModal :propsObj="propsObjInData"></ExportModal>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';
import * as utils from '../../../../../../utils/index';
import ExportModal from '../../../../../../components/export_modal';

export default {
  components: {
    ExportModal,
  },
  props: {
    value: Object,
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.clientCode = val.clientCode;
          this.getList();
        }
      },
    },
  },
  data() {
    return {
      storeList: [], // 盘库明细
      clientCode: '',
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      propsObjInData: {},
    };
  },
  created() {

  },
  methods: {
    getList() {
      const params = {
        clientCode: this.clientCode,
        stepCode: 'sfa_step_code_stock',
        ...this.page,
      };
      request.post('/sfa/sfavisitstepstockinventory/queryStockInventoryReportDetail', params).then((res) => {
        if (!res.success) return false;
        const list = res.result.data || [];
        this.page.total = res.result.count || 0;
        this.storeList = list;
      });
    },
    /** @desc size变化 */
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    },
    /** @desc page变化 */
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    /** @desc 导出按钮 */
    handleExport() {
      const sid = utils.getUuid(32, 16);
      const params = {
        sid,
        requestUrl: '/sfa/sfavisitstepstockinventory/queryStockInventoryReportDetail',
        functionCode: 'sfaCenter_visitManage_inventoryDetail_detailForm_export',
        parentCode: this.$store.state.menus.selectMenu,
        stepCode: 'sfa_step_code_stock',
        clientCode: this.clientCode,
      };
      request.post('/excel/excelController/repeatedWriteExport', params).then((res) => {
        if (!res.success) return false;
        this.propsObjInData = {
          functionCode: params.functionCode,
          requestUrl: params.requestUrl,
          visible: true,
          exportMessage: '',
          isExport: true,
          formData: {
            stepCode: 'sfa_step_code_stock',
            clientCode: this.clientCode,
          },
          sid,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-collapse-item__header{
  height: auto;
}
/deep/ .page-pageination {
  text-align: right;
  padding-top: 10px;
  .el-input {
    display: inline-block !important;
  }
}
.margin-bottom-10{
  margin-bottom: 10px;
}
.form-title {
  font-weight: 600;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  span{
    font-size: 16px;
    line-height: 20px;
    padding-right: 10px;
  }
}
</style>
